import React from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./ViewSignedTripSlip.css";

const ViewSignedTripSlip = ({ signedSlip, open, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!signedSlip) return null;

  // Safely format the address, ensuring fields exist
  const formatAddress = (address) => {
    if (!address) return "N/A";
    const {
      streetAddress = "N/A",
      streetAddress2 = "",
      city = "N/A",
      state = "N/A",
      zipCode = "N/A",
    } = address;
    return `${streetAddress}${
      streetAddress2 ? `, ${streetAddress2}` : ""
    }, ${city}, ${state}, ${zipCode}`;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className="modal-box" sx={{ width: isMobile ? "90%" : "600px" }}>
          <Typography variant="h4" gutterBottom>
            Signed Trip Slip Details
          </Typography>
          <Card>
            <CardContent>
              <Typography variant="subtitle1">
                <strong>Guardian:</strong> {signedSlip.guardianFirstName}{" "}
                {signedSlip.guardianLastName}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Guardian Address:</strong>{" "}
                {formatAddress(signedSlip.guardianAddress)}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Child:</strong> {signedSlip.childData.childFirstName}{" "}
                {signedSlip.childData.childLastName}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Allergies:</strong>{" "}
                {signedSlip.childData.childAllergies || "None"}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Child Address:</strong>{" "}
                {signedSlip.childData.childAddressDifferent
                  ? formatAddress(signedSlip.childData.childAddress)
                  : formatAddress(signedSlip.guardianAddress)}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Grade:</strong> {signedSlip.childData.grade || "N/A"}
              </Typography>
              <Typography variant="subtitle1">
                <strong>Teacher:</strong>{" "}
                {signedSlip.childData.teacher || "N/A"}
              </Typography>
              <Typography variant="subtitle1">
                <strong>School:</strong> {signedSlip.childData.school || "N/A"}
              </Typography>
              <Box className="emergency-info" sx={{ mt: 2 }}>
                <Typography variant="subtitle1">
                  <strong>Emergency Information:</strong>
                </Typography>
                <Typography variant="body2">
                  Contact:{" "}
                  {`${signedSlip.emergencyInformation.emergencyContactFirstName} ${signedSlip.emergencyInformation.emergencyContactLastName}`}
                </Typography>
                <Typography variant="body2">
                  Relationship:{" "}
                  {signedSlip.emergencyInformation.emergencyRelationship}
                </Typography>
                <Typography variant="body2">
                  Best Contact Method:{" "}
                  {signedSlip.emergencyInformation.emergencyBestContact}
                </Typography>
                <Typography variant="body2">
                  Contact Info:{" "}
                  {signedSlip.emergencyInformation.emergencyContactEmail ||
                    signedSlip.emergencyInformation.emergencyContactInfo ||
                    "N/A"}
                </Typography>
              </Box>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                <strong>Signed On:</strong>{" "}
                {new Date(signedSlip.createdAt).toLocaleDateString()}
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">
                  <strong>Signature:</strong>
                </Typography>
                <img
                  src={signedSlip.guardianSignature}
                  alt="Guardian Signature"
                  className="signature-img"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ViewSignedTripSlip;
